import Vue from 'vue';
import {
  WebsitesService,
  UserService
} from "./common/api.service.js";
import {domDecoder} from "./common/helpers.js";
import {store} from './store/UserSettingStore.js'
import { debounce } from 'lodash';
import { usePermissionsStore } from './store/PermissionStore.js';
import { mapState } from 'pinia';
import { TierUpgradeButton } from "./tiers/TierUpgradeButton.js"

// Advertiser (frontend) = Website (backend)
let AdvertiserMenuInit = (UserService, userId, dataStore, menuInit) => {
    return UserService.get(userId).then(response => {
        if(response.settings.archived_filter) {
            dataStore.setFilterSettingDisplay(response.settings.archived_filter);
        }
        menuInit();
    }).catch(e => {
        console.log(e);
    });
}

export const AdvertiserMenu = Vue.extend({
    data: function() {
        return ({
            store: store(),
            permissionsStore : usePermissionsStore(),
            advertisers:[],
            recentVisitedAdvertisers: [],
            selectedAdvertiser_: this.selectedAdvertiserName,
            showMenu: false,
            static_url: adretriever.STATIC_URL,
            searchQuery: "",
            isShowSearchPanel: false,
            isSearching: false,
            searchResults: [], // Only storing advertisers result with search query
            isShowFilterPanel: false,
            // Comparing to `totalAdvertisers`,
            // if total of advertisers for this user is less than the threshold, 
            // "Recent" section in advertiser list will be hidden
            hideRecentThreshold: 5,
            totalAdvertisers: 0, // User's total accessible advertisers amount
            advertiserPageSize: "10", // Use string type to make sure matched to current route query's type
            advertiserPageNumber: 1, // For advertiser paging, default is 1 as api param "page" starts at 1
            isLastAdvertiserPageShown: false, // Indicate if last page of advertiser is showing
            isApplyFilterError: false, // To show error messgae in filter panel or not,
            isLoadingAdvertisers: true,
            scrollDebounce: true,  // Prevent `detectScrolledToBottom` to have multiple detection
            // For getting the correct height of dropdown container to detect scroll to bottom, 
            // have to show the dropdown for few secs before add evenet listener
            isTempShowMenu: true,
            modalKey: "AdvertiserCreation",
        })
    },

    props: {
        'createURL': String,
        'selectedAdvertiserDisplayName': String,
        'selectedAdvertiserName': String,
        'selectedAdvertiserId' : String,
        'account': String,
        'isAuthenticated': String,
        'userId' : String,
        'staticUrl': String,
    },
    components: {
        TierUpgradeButton
    },
    computed: {
        ...mapState(usePermissionsStore, ['permissions']),
        menuAttrs() {
            if (this.showMenu) {
                return {
                    'data-toggle': "dropdown",
                    'aria-haspopup': "true",
                    'aria-expanded': "false"
                }
            }
            return {}
        },
        filterSettingDisplay:  {
            get () {
                // Get filterSettingDisplay data from the store, 
                // use this to display checkboxs in filter panel
                return this.store.userSetting.filterSettingDisplay
            },
        },
    },
     mounted: function (){
        AdvertiserMenuInit(UserService, this.userId, this.store, this.init);
        // Detect if the menu is closed
        const _this = this
        $(document).click(function() {
            if(!$('.dropdown-menu').data('open')) {
                _this.resetMenu()
            }
        });
    },

    methods: {
        init(){
            if(this.checkAdvertiser()) {
                this.resetSearch();
                this.resetAdvertiserList();
                this.getAdvertisers('paging');
                this.detectScrolledToBottom();
            }
        },

        resetSearch() {
            this.searchQuery = ""
            this.searchResults = []
        },

        resetAdvertiserList() {
            this.advertisers = []
            this.recentVisitedAdvertisers = []
            this.advertiserPageNumber = 1
        },

        getParams(type) {
            let params = new URLSearchParams();

            switch(type) { 
                case 'search': { 
                    params.append('search_query', this.searchQuery);
                    params.append('website_status', this.store.getFilterSettingParamValue());
                    break; 
                } 
                case 'paging': { 
                    params.append('page_size', this.advertiserPageSize);
                    params.append('get_recent_websites', true);
                    params.append('website_status', this.store.getFilterSettingParamValue());
                    if(this.advertiserPageNumber > 1) {
                        params.append('page', this.advertiserPageNumber);
                    }
                    break; 
                }
                default: { 
                    break; 
                } 
            }
            return params;
        },

        async getAdvertisers(type) {
            let params = this.getParams(type);

            WebsitesService.query(params=params).then(response => {
                switch(type) { 
                    case 'search': { 
                        this.getSearchAdvertisersCallback(response);
                        break; 
                    } 
                    case 'paging': { 
                        this.getPagingAdvertisersCallback(response);
                    }
                    default: { 
                        break; 
                    } 
                }
                this.isLoadingAdvertisers = false;
            }).catch(e => {
                console.log(e);
            });
        },

        getSearchAdvertisersCallback(response) {
            this.searchResults = response.results
            this.isSearching = false
        },

        getPagingAdvertisersCallback(response) {
            if(this.advertiserPageNumber > 1) {
                this.advertisers = this.advertisers.concat(...response.results) // Concat new page advertisers into old advertisers array
            }else{
                // First time get advertisers
                this.advertisers = response.results;
                this.totalAdvertisers = response.count;
            }
            if(!response.next || this.advertiserPageNumber == response.total_pages) {
                this.isLastAdvertiserPageShown = true
            }
            if(response.recent_websites) {
                this.recentVisitedAdvertisers = response.recent_websites
            }
            this.isLoadingAdvertisers = false
            let _this = this;
            setTimeout(function () {
                if(!_this.scrollDebounce) {
                    _this.scrollDebounce = true
                }
            }, 500); // To prevent multiple call
        },

        loadMoreAdvertisers() {
            if(!this.isLastAdvertiserPageShown) {
                this.advertiserPageNumber++;
                this.getAdvertisers('paging');
            }
        },

        checkAdvertiser() {
            if (this.selectedAdvertiserName !== "") {
                this.showMenu = true;
                this.updateRecentlyViewedAdvertiser();
                return true;
            }
            else{
                this.showMenu = false;
                return false;
            }
        },

        updateRecentlyViewedAdvertiser() {
            // Update user setting field 'recently_viewed_websites'
            let params = { website_viewed : this.selectedAdvertiserId };
                    
            UserService.updateSetting(this.userId, params).catch(e => {
                console.log(e);
            });
        },

        escapeSpecial(str){
            // verify input is a string
            let return_string = ""
            if (typeof str === 'string' || str instanceof String) {
                return_string = domDecoder(str)
            }
            return return_string
        },

        createAdvertiser() {
                let createPage =  window.location.origin+this.createURL
                window.location.href= createPage
        },

        goToAdvertiserSetting() {
            window.location.href = window.location.origin +  "/" + this.selectedAdvertiserName + "/settings"; 
        },

        toggleSearchPanel() {
            this.isShowSearchPanel = !this.isShowSearchPanel
            if(this.isShowSearchPanel) {
                this.$nextTick(() => {
                    this.$refs.searchInputField.focus();
                })
            }
            // Hide filter panel
            this.isShowFilterPanel = false
        },

        toggleFilterPanel() {
            this.isShowFilterPanel = !this.isShowFilterPanel
            // Hide search panel
            this.isShowSearchPanel = false
        },

        // Called when menu closed
        resetMenu (){
            // Hide filter panel
            this.isShowFilterPanel = false
            // Hide search panel
            this.isShowSearchPanel = false
            this.resetSearch()
        },

        async updateUserFilterSetting() {
            // Hide error first
            this.isApplyFilterError = false;
            const selectedFilter = this.filterSettingDisplay.filter((obj) => obj.value).map((obj) => obj.label);
            if(!selectedFilter.length) {
                this.isApplyFilterError = true;
                return;
            }
            // User setting filer { archived_filter : all / active / archived}
            let websiteFilter = selectedFilter.length == 2 ? 'all' : selectedFilter[0]
            let params = { archived_filter : websiteFilter };

            UserService.updateSetting(this.userId, params).then(response => {
                this.store.setFilterSettingDisplay(websiteFilter);
                // Fire inti function again to trigger refresh advertiser list
                this.init();
                // Back to listing panel after applied filter
                this.isShowFilterPanel = false
            }).catch(e => {
                console.log(e);
            });
        },
        
        detectScrolledToBottom() {
            if(this.isLastAdvertiserPageShown) return // Stop detection if last page shown

            // Detect when scrolled to bottom.
            const listElm = this.$refs.advertisersLists;
            listElm.addEventListener('scroll', e => {
                if((Math.ceil( listElm.scrollTop + listElm.offsetHeight ) >= listElm.scrollHeight - 10) && this.scrollDebounce) {
                    this.scrollDebounce = false;
                    this.loadMoreAdvertisers();
                }
            });
            if(this.isTempShowMenu) this.isTempShowMenu = false;
        },

        redirectToAdvertiser(advertiserName) {
            let baseUrl = window.location.origin;
            window.location.href = baseUrl + '/' + advertiserName;
        },
        closeMenu() {
            this.$refs.dropdown.hide();
            this.resetMenu();
        },

    },
    
    // truncate strings if too long
    filters: {
        truncate: function(data,num){
            return data.length > num ? data.substring(0, num) + '...' : data;
            
        }
    },

    watch: {
        searchQuery: debounce(function (newURL, oldURL) {
            this.isSearching = true;
            this.searchResults = []; // Clear search result
            if(this.searchQuery) {
                this.getAdvertisers('search');
            }else{
                this.isSearching = false;
            }
        }, 1000),
    },

    template: `
    <div class="advertiser-menu dropdown mx-3 p-0">
        <b-dropdown name="advertiser-menu" ref="dropdown" variant="link" toggle-class="border-0" menu-class="pt-0" no-caret>
            <template #button-content>
                <div class="dropdown-toggle">
                    <p class="selected-advertiser-name mb-0 d-inline-block align-middle" :data-text="escapeSpecial(selectedAdvertiserDisplayName)">{{escapeSpecial(selectedAdvertiserDisplayName) | truncate(25) }}</p>
                </div>
            </template>
            <div class="advertiser-dropdown py-3" :class="isTempShowMenu ? 'temp-show' : ''" aria-labelledby="dropdownMenu" @click.stop>
                <div class="dropdown-menu-container position-relative">
                    <!-- Advertiser list Panel -->
                    <div class="advertiser-list-container" :class="!isShowSearchPanel && !isShowFilterPanel ? 'show' : 'hide'">
                        <div class="header-row d-flex justify-content-between mb-3 pr-4">
                            <div>
                                <button class="btn border-0 p-0 selected-advertiser-name d-inline text-decoration-none spruce-color text-left" @click="closeMenu">{{escapeSpecial(selectedAdvertiserDisplayName)}}</button>
                                <button v-if="isAuthenticated == 'True'" type="button" title="Advertiser Settings" class="btn border-0 ml-3 p-0" @click=goToAdvertiserSetting>
                                    <font-awesome-icon :icon="['fas', 'gear']" class="m-0 text-muted" />
                                </button>
                            </div>
                            <div class="mt-1">
                                <button type="button" class="btn border-0 p-0 mx-1 text-muted" @click=toggleSearchPanel>
                                    <font-awesome-icon :icon="['fas', 'magnifying-glass']" size="lg" aria-hidden="true"/>
                                </button>
                                <button type="button" class="btn border-0 p-0 mx-1 text-muted" @click=toggleFilterPanel>
                                    <font-awesome-icon :icon="['fas', 'sliders']" size="lg" aria-hidden="true" />
                                </button>
                            </div>
                        </div>
                        <div class="dropdown-divider"></div>
                        <div 
                        :class="isLoadingAdvertisers || !advertisers.length ? 'show' : 'hide'"
                        class="load-advertisers-overlay my-2 position-absolute d-flex align-items-center justify-content-center w-100 h-75"
                        >
                                <div class="spinner-grow"></div>
                        </div>
                        <div v-if="!isLoadingAdvertisers || !advertisers.length" class="advertisers-lists" ref="advertisersLists">
                            <div class="recent-advertisers" :class="totalAdvertisers <= hideRecentThreshold || !recentVisitedAdvertisers.length ? 'hide' : 'show'">
                                <p class="dropdown-header text-light-custom read-only mb-0 pl-0">Recent</p>
                                <div class="advertisers-list d-flex flex-column">
                                    <a
                                    v-for="advertiser in recentVisitedAdvertisers" :value="advertiser.id" 
                                    :key="advertiser.id" 
                                    class="advertisers d-flex border-0 py-2 pr-3 justify-content-between eggplant-color"
                                    :class="advertiser.name == selectedAdvertiserName ? 'selected' : ''"
                                    href="#"
                                    @click.prevent="redirectToAdvertiser(advertiser.name)">
                                        <div>
                                            <span :class="advertiser.archived ? 'badge-danger' : 'badge-success'" class="badge badge-pill">&nbsp;</span>
                                            <span>{{ advertiser.display_name | truncate(32) }}</span>
                                        </div>
                                        <span class="text-right">{{ advertiser.account_name | truncate(20) }}</span>
                                    </a>
                                </div>
                            </div>
                            <div class="all-advertisers">
                                <p class="dropdown-header text-light-custom read-only mb-0 pl-0">All Advertisers</p>
                                <div class="advertisers-list d-flex flex-column">
                                    <a 
                                    v-for="advertiser in advertisers" 
                                    :value="advertiser.id" 
                                    :key="advertiser.id" 
                                    class="advertisers d-flex border-0 py-2 pr-3 justify-content-between eggplant-color" 
                                    :class="advertiser.name == selectedAdvertiserName ? 'selected' : ''"
                                    href="#"
                                    @click.prevent="redirectToAdvertiser(advertiser.name)">
                                        <div>
                                            <span :class="advertiser.archived ? 'badge-danger' : 'badge-success'" class="badge badge-pill">&nbsp;</span>
                                            <span>{{ advertiser.display_name | truncate(32) }}</span>
                                        </div>
                                        <span class="text-right">{{ advertiser.account_name | truncate(20) }}</span>
                                    </a>
                                </div>
                            </div>
                            <div class="advertiser-create position-absolute d-flex justify-content-end">
                                <a>
                                    <TierUpgradeButton
                                    @defaultFunction="createAdvertiser"
                                    :isShowDefault="permissions.tiered_website_add"
                                    buttonText="Add Advertiser"
                                    :modalKey="modalKey"
                                    :staticUrl="staticUrl"
                                    :accountId="account" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <!-- Search Panel -->    
                    <div class="search-container position-absolute w-100 h-100 flex-column pr-4" :class="isShowSearchPanel ? 'show d-flex' : 'hide'">
                        <div class="header-row d-flex justify-content-between">
                            <b-input-group class="search-input-group border w-100">
                                <b-input-group-prepend>
                                    <span class="input-group-text border-0"><font-awesome-icon :icon="['fas', 'magnifying-glass']" size="lg" aria-hidden="true"/></span>
                                </b-input-group-prepend>
                                <b-form-input v-model="searchQuery" class="search-input border-0" size="md" placeholder="Search for advertisers" ref="searchInputField"></b-form-input>
                            </b-input-group>

                            <button type="button" class="btn" @click="toggleSearchPanel">
                                <font-awesome-icon :icon="['fas', 'xmark']" class="text-muted" size="lg" />
                            </button>
                        </div>

                        <div class="search-result-container w-100 h-100 py-2 d-flex flex-column justify-content-start overflow-auto">
                            <div 
                            :class="isSearching ? 'show' : 'hide'"
                            class="search-overlay my-2 position-absolute d-flex align-items-center justify-content-center w-100 h-100"
                            >
                                <div class="spinner-grow"></div>
                            </div>
                            <div v-if="!searchResults.length" class="text-light-custom flex-grow-1 d-flex justify-content-center align-items-center">
                                    <p>No matching results</p>
                            </div>
                            <div v-else class="text-center d-flex flex-column h-100 pt-3"> 
                                <a 
                                v-for="advertiser in searchResults" 
                                :value="advertiser.id" 
                                :key="advertiser.id" 
                                class="advertisers d-flex border-0 py-2 justify-content-between" 
                                :class="advertiser.name == selectedAdvertiserName ? 'selected' : ''"
                                href="#"
                                @click.prevent="redirectToAdvertiser(advertiser.name)">
                                    <div>
                                        <span :class="advertiser.archived ? 'badge-danger' : 'badge-success'" class="badge badge-pill">&nbsp;</span>
                                        <span>{{ advertiser.display_name | truncate(23) }}</span>
                                    </div>
                                    <span>{{ advertiser.account_name }}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <!-- Filter Panel -->
                    <div class="filter-container position-absolute w-100 pr-4" :class="isShowFilterPanel ? 'show' : 'hide'">
                        <div class="header-row d-flex justify-content-between">
                            <p class="heading mb-0 d-inline-block align-middle font-weight-bold">Filters</p>
                            <button type="button" class="btn" @click="toggleFilterPanel">
                                <font-awesome-icon :icon="['fas', 'xmark']" class="text-muted" size="lg" />
                            </button>
                        </div>
                        <div class="dropdown-divider"></div>
                        <div class="filter-selection-container w-100 d-flex flex-column align-items-start">
                            <p class="dropdown-header text-light-custom read-only mb-0 pl-0">Advertiser Status</p>
                            <div v-for="(setting, index) in filterSettingDisplay" v-bind:key="index">
                                <label class="text-capitalize">
                                    <input class="mx-1" type="checkbox" :value="setting.label" v-model="setting.value" :disabled="setting.label == 'active'"/>
                                    {{setting.label}}
                                </label>
                            </div>
                            <p class="text-danger" :class="isApplyFilterError ? 'show' : 'hide'">Please at least select one type of advertisers to show</p>
                            <button class="btn btn-primary align-self-end" @click="updateUserFilterSetting">
                                Apply
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </b-dropdown>
    </div>
    `
});
