
// Libraries
import Vue from 'vue';
import 'bootstrap';
import {
    LayoutPlugin, // For BRow and BCol components
    ModalPlugin,
    ButtonPlugin,
    AlertPlugin,
    TooltipPlugin,
    CollapsePlugin,
    SpinnerPlugin,
    DropdownPlugin, // For BDropdown, BDropdownHeader, BDropdownDivider, BDropdownItem, BDropdownGroup
    InputGroupPlugin,
    FormPlugin, // For BFormInput, BFormSelect, BFormFile, BFormInvalidFeedback, BFormGroup
    PopoverPlugin,
    CardPlugin, // For BCard, BCardGroup, BCardText
    TablePlugin,
    PaginationPlugin,
    LinkPlugin,
    BadgePlugin,
    TabsPlugin, // Includes Tabs and Tab components
    NavPlugin, // Includes Nav and NavItem components
    ProgressPlugin,
    FormInputPlugin,
    FormCheckboxPlugin,
    FormSelectPlugin,
    FormFilePlugin,
    FormGroupPlugin,
    FormTextareaPlugin,
    FormRadioPlugin,
    FormDatepickerPlugin, //Date picker
    ListGroupPlugin
} from 'bootstrap-vue';
import Cookies from "js-cookie";
import axios from 'axios'
import VueAxios from 'vue-axios'
import Datepicker from 'vuejs-datepicker'
import { createPinia } from 'pinia';
// Base files
import Icons from './registerIcons';
// Style
import '../scss/index.scss'
// Service
import ApiService from "./src/common/api.service.js";
// Component
import Wrapper from './src/Wrapper';
// Utils
import './src/utils/dom_utils.js';
import './src/utils/bootstrap_utils.js';

// Register each component and directive globally
// Single Vue.use will not work
// Bootstrap Vue
Vue.use(LayoutPlugin);
Vue.use(FormPlugin);
Vue.use(DropdownPlugin);
Vue.use(TooltipPlugin);
Vue.use(PopoverPlugin);
Vue.use(CardPlugin);
Vue.use(TablePlugin);
Vue.use(PaginationPlugin);
Vue.use(LinkPlugin);
Vue.use(BadgePlugin);
Vue.use(TabsPlugin);
Vue.use(NavPlugin);
Vue.use(ProgressPlugin);
Vue.use(InputGroupPlugin);
Vue.use(SpinnerPlugin);
Vue.use(CollapsePlugin);
Vue.use(ModalPlugin);
Vue.use(ButtonPlugin);
Vue.use(AlertPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormFilePlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(FormRadioPlugin);
Vue.use(FormDatepickerPlugin);
Vue.use(ListGroupPlugin);
// Libraries
Vue.use(VueAxios, axios);
Vue.use(createPinia());
Vue.component('datepicker', Datepicker)
Vue.component('font-awesome-icon', Icons);

window.onload = function () {
    // Init ApiService
    ApiService.init(window.adretriever.Basic.siteUrl + "/api/", Cookies.get('csrftoken'));

    // Retrieve the current Vue component from the data-* attribute
    let vueAppDiv = document.querySelector('#vue-app')
    let specialDiv = document.querySelector('#hybrid-div')
    if (!vueAppDiv && !specialDiv) {
        console.log("Vue app target #vue-app or #hybrid-div not found.")
    }

    if (vueAppDiv) {
        handleNormalPage(vueAppDiv)
    } else if (specialDiv) {
        handleHybridPage(specialDiv)
    } else {
        handleNoVueComponentPage();
    }
};

//Handle normal pages flow to include component inside Wrapper (e.g. Dashboard)
function handleNormalPage(div) {
    const currentComponent = div?.dataset.template
    const currentComponentPath = div && div.dataset.hasOwnProperty('templatePath') ? div.dataset.templatePath : ""
    let importComponentPath = currentComponentPath.length ? currentComponentPath + currentComponent : currentComponent
    const currentComponentPropsData = window.adretriever[currentComponent]?.propsData || {};

    // Dynamically import the current Vue component if found
    import(`./src/${importComponentPath}.js`).then(module => {
        const theComponent = module[currentComponent];
        // Throw error for debug
        if(!theComponent) {
            console.error(`The component: ${currentComponent} is undefined. Please check if the js file exists and if the export component name matches the file name.` )
        }

        // Mount the current Vue component to the #main_app div
        new Vue({
            // Naming will help debug in vue.js devtool in inspector
            name: 'Main App',
            // Add props to the component and pass theComponent as a child of Wrapper
            // so it will be rendered where the <slot></slot> tag is in Wrapper.js
            render: h => h(
                Wrapper, { props: window.adretriever },
                [h(theComponent, { props: currentComponentPropsData })]
            )
        }).$mount('#main_app');

    });
}

// Handle pages using Django template with Vue components (e.g. Advertiser setting's pixel and conversion page)
// To start up two app instead of one, to ensure content of Vue components injects into correct div
function handleHybridPage(div) {
    const currentComponent = div?.dataset.template
    const currentComponentPath = div && div.dataset.hasOwnProperty('templatePath') ? div.dataset.templatePath : ""
    let importComponentPath = currentComponentPath.length ? currentComponentPath + currentComponent : currentComponent

    // Mount the current Vue component to the #main_app div
    new Vue({
        // Naming will help debug in vue.js devtool in inspector
        name: 'Main App',
        // Add props to the component and pass Component as a child of Wrapper
        // so it will be rendered where the <slot></slot> tag is in Wrapper.js
        render: h => h(
            Wrapper, { props: window.adretriever },

        )
    }).$mount('#main_app');

    import(`./src/${importComponentPath}.js`).then(module => {
        const theComponent = module[currentComponent];
        // Throw error for debug
        if(!theComponent) {
            console.error(`The component: ${currentComponent} is undefined. Please check if the js file exists and if the export component name matches the file name.` )
        }
        
        // Mount the current Vue component to the #hybrid-div div
        new Vue({
            // Naming will help debug in vue.js devtool in inspector
            name: 'Hybrid container',
            // Add props to the component
            render: h => h(theComponent, { props: window.adretriever[currentComponent].propsData })

        }).$mount('#hybrid-div');
    });
}

// Only render Wrapper in Vue app if the page only have Django template, did not have any Vue component
// (e.g. Advertiser setting's data source page)
function handleNoVueComponentPage() {
    // Mount the current Vue component to the #main_app div
    new Vue({
        // Naming will help debug in vue.js devtool in inspector
        name: 'Main App',
        // Add props to the component
        render: h => h(
            Wrapper, { props: window.adretriever },

        )
    }).$mount('#main_app');
}